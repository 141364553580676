<!-- REMEMBER TO RESTRICT FOR EACH CARD -->
<!-- REMEMBER TO RESTRICT FOR EACH CARD -->
<!-- REMEMBER TO RESTRICT FOR EACH CARD -->
<!-- REMEMBER TO RESTRICT FOR EACH CARD -->
<!-- REMEMBER TO RESTRICT FOR EACH CARD -->
<!-- REMEMBER TO RESTRICT FOR EACH CARD -->
<!-- REMEMBER TO RESTRICT FOR EACH CARD -->
<!-- REMEMBER TO RESTRICT FOR EACH CARD -->

<template>
	<div>
		<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

			<h1 class="text-3xl font-bold mb-10">Create a New Card</h1>

			<form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16">

				<template v-if="getFormError(form)">
					<div class="alert alert-red-soft mb-16">
						<div class="alert-icon">!</div>
						<span>{{ getFormError(form) }}</span>
					</div>
				</template>

				<div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mb-10">
					<div class="col-span-1">
						<div
							class="flex items-center rounded border py-4 px-6 text-sm cursor-pointer"
							:class="{
								'border-blue-500 bg-blue-100 text-blue-500': form.data.type.value === 'credit',
								'text-gray-500 border-blue-200 hover:bg-gray-100': form.data.type.value !== 'credit'
							}"
							@click="() => form.data.type.value = 'credit'"
						>
							<ion-icon name="card-outline" class="text-xl mr-3"></ion-icon>
							Credit Card
						</div>
					</div>
					<div class="col-span-1">
						<div
							class="flex items-center rounded border py-4 px-6 text-sm cursor-pointer"
							:class="{
								'border-blue-500 bg-blue-100 text-blue-500': form.data.type.value === 'debit',
								'text-gray-500 border-blue-200 hover:bg-gray-100': form.data.type.value !== 'debit'
							}"
							@click="() => form.data.type.value = 'debit'"
						>
							<ion-icon name="card-outline" class="text-xl mr-3"></ion-icon>
							Debit Card
						</div>
					</div>
				</div>

				<form-group
					type="select"
					:options="groupOptions"
					left-icon="people-circle-outline"
					name="group"
					:form="form"
					v-model="form.data.group.value"
					:loading="groups.loading"
				>
					Department
				</form-group>
				<div class="-mt-6 mb-6">
					<div class="text-orange-500 text-sm mb-2" v-if="!groupOptions.length && !groups.loading">
						No departments available
					</div>
					<router-link :to="{ name: 'groups-new' }" class="text-sm text-gray-700 flex items-center">
						<span class="badge-dot badge-blue-soft text-base mr-2">+</span> 
						<span style="margin-top: 1px">Click to create a new group</span>
					</router-link>
				</div>

				<!-- <div class="mb-10" v-if="!form.data.group.value">
					<div class="alert alert-gray-soft mb-2">
						<span class="alert-icon">!</span>
						<span class="text-gray-700">Select A Department</span>
					</div>
				</div> -->
				
				<form-group
					type="select"
					:options="staffOptions"
					left-icon="person-outline"
					name="staff"
					:form="form"
					v-model="form.data.staff.value"
					:loading="staff.loading"
				>
					Staff
				</form-group>
				<div class="-mt-6 mb-6">
					<div class="text-orange-500 text-sm mb-2" v-if="!staffOptions.length && !staff.loading && form.data.group.value">
						No staff available in this department
					</div>
					<router-link :to="{ name: 'staff-new' }" class="text-sm text-gray-700 flex items-center">
						<span class="badge-dot badge-blue-soft text-base mr-2">+</span> 
						<span style="margin-top: 1px">Click to register a new staff</span>
					</router-link>
				</div>

				<div class="text-center">
					<button type="submit" class="btn btn-blue btn-md" :disabled="form.loading || !this.canRequestCards">
						<span v-if="form.loading">Loading</span>
						<span v-else>Submit</span>
					</button>
				</div>

			</form>

		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-sm mb-10">
				Your card request has been registered. You'll be notified when your request is approved.
			</div>

			<button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
				Okay
			</button>
		</modal>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				checkmark: require('@/assets/checkmark-base.svg'),
				groups: this.$options.resource([]),
				staff: this.$options.resource([]),
				plan: this.$options.resource(null),
				form: this.$options.basicForm([
					'group', 'staff', 'manager', {name: 'type', value: 'credit'}
				]),
			}
		},
		computed: {
			wallet() {
				return this.resources.wallet;
			},
			canRequestCreditCard() {
				return this.wallet.data?.credit_limit
			},
			canRequestDebitCard() {
				return this.wallet.data?.debit
			},
			canRequestCards() {
				return this.canRequestCreditCard || this.canRequestDebitCard;
			},
			groupOptions() {
				return this.groups.data.map(group => ({ title: group.name, value: group.id }));
			},
			staffOptions() {
				return this.staff.data.filter(staff => staff.group_id == this.form.data.group.value).map(staff => ({ title: staff.name, value: staff.id }));
			},
		},
		beforeMount() {
			this.getGroups();
			this.getStaff();
			this.getActivePlan();
		},
		mounted() {
			if (this.$route.query.cardType?.match(/credit|debit/)) {
				this.form.data.type.value = this.$route.query.cardType
			}
		},
		watch: {
			'form.data.group.value'() {
				this.form.data.staff.value = '';
			},
			'form.data.type.value'() {
				this.form.data.manager.value = '';
			},
		},
		methods: {
			async submit() {
				const type = this.form.data.type.value;

				this.form.loading = true;
				await this.sendRequest(`corporate.cards.${type}.new`, {
					data: {
						companyId: this.user.company_id,
						groupId: this.form.data.group.value,
						employeeId: this.form.data.staff.value,
						planId: this.plan.data?.company_plan_id
					},
					success: () => {
						this.$refs.successModal.open();
						this.form = this.resetForm(this.form);
					},
					error: error => {
						this.form.error = error;
						console.log(error);
					}
				})
				this.form.loading = false;
			},
			async getActivePlan() {
				this.plan.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/plan/unique-plan/${this.user.company_id}`,
					headers: this.headers,
					success: response => {
						this.plan.data = response.data.data;
					},
					error: error => {
						this.plan.error = error;
					}
				});
				this.plan.loading = false;
			},
			async getGroups() {
				this.groups.loading = true;
				await this.sendRequest('corporate.groups.all', {
					success: (response) => {
						this.groups.data = response.data.groups;
					}
				})
				this.groups.loading = false;
			},
			async getStaff() {
				this.staff.loading = true;
				await this.sendRequest('corporate.staff.all', {
					success: response => {
						this.staff.data = response.data.data;
					}
				})
				this.staff.loading = false;
			},
		}
	}
</script>